<template>
    <div class="modal deposit">
        <img class="close" @click="closeCheckModal" src="./../../assets/close.svg" />
        <div class="header">
            <div class="header-buttons">
                <a class="router-link-active">{{ $t('Checkout') }}</a>
            </div>
        </div>
        <div class="overlay"></div>
        <div class="wrapper">
            <div class="container">
                <div class="form-fields-wrapper">
                    <div class="title small">{{ $t('Checkout') }}</div>
                    <label>
                        <div class="description">{{ $t('Name') }}:</div>
                        <input type="text" :placeholder="$t('Name')" v-model="name" required />
                        <div v-if="!nameValid" class="error-description red">
                            {{ $t('Name is required') }}
                        </div>
                    </label>
                    <label>
                        <div class="description">{{ $t('Surname') }}:</div>
                        <input type="text" :placeholder="$t('Surname')" v-model="surname" required />
                        <div v-if="!surnameValid" class="error-description red">
                            {{ $t('Surname is required') }}
                        </div>
                    </label>
                    <label>
                        <div class="description">{{ $t('Email') }}:</div>
                        <input type="text" :placeholder="$t('Email')" v-model="email" required />
                        <div v-if="!emailValid" class="error-description red">
                            {{ $t('Email is required') }}
                        </div>
                    </label>
                    <label>
                        <div class="description">{{ $t('Phone') }}:</div>
                        <input type="tel" :placeholder="$t('Phone')" v-model="phone" required />
                        <div v-if="!phoneValid" class="error-description red">
                            {{ $t('Phone is required') }}
                        </div>
                    </label>
                    <label>
                        <div class="description">{{ $t('Steam ID') }}:</div>
                        <input type="text" :placeholder="$t('Steam ID')" v-model="steamId" required />
                        <div v-if="!steamIdValid" class="error-description description red">
                            {{ $t('Steam ID is required') }}
                        </div>
                    </label>
                    <label>
                        <div class="description">{{ $t('Country') }}:</div>
                        <select v-model="country" required>
                            <option disabled selected value="">{{ $t('Country') }}</option>
                            <option v-for="(item, i) in countryOptions" :value="item" :key="i">{{ item.title
                                }}</option>
                        </select>
                        <div v-if="!countryValid" class="error-description description red">
                            {{ $t('Country is required') }}
                        </div>
                    </label>
                    <label>
                        <div class="description">{{ $t('City') }}:</div>
                        <input type="text" :placeholder="$t('City')" v-model="city" required />
                        <div v-if="!cityValid" class="error-description description red">
                            {{ $t('City is required') }}
                        </div>
                    </label>
                    <label>
                        <div class="description">{{ $t('Address') }}:</div>
                        <input type="text" :placeholder="$t('Address')" v-model="address" required />
                        <div v-if="!addressValid" class="error-description description red">
                            {{ $t('Address is required') }}
                        </div>
                    </label>
                    <label>
                        <div class="description">{{ $t('Post Code') }}:</div>
                        <input type="text" :placeholder="$t('Post Code')" v-model="postCode" required />
                        <div v-if="!postCodeValid" class="error-description description red">
                            {{ $t('Post Code is required') }}
                        </div>
                    </label>
                    <div class="chekbox-container">
                        <label class="chekbox-label">
                        <div class="chekbox-label-wrapper">
                            <input type="checkbox" name="terms" v-model="terms" />
                            <div class="checkbox"></div>
                            <span class="title">{{ $t("I agree with") }} </span>
                            <a @click="goToPage('privacy-policy')" class="title">{{ $t("Privacy Policy") }}</a>
                            <span class="title"> {{ $t("and") }} </span>
                            <a @click="goToPage('terms-and-conditions')" class="title">{{ $t("Terms and conditions") }}</a>
                        </div>
                        </label>
                    </div>
                    <button :class="['button deposit', {'disabled': !requiredFieldsAreFilled}]" @click="submitCheckout">
                        <span>{{ $t('Order') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
    name: 'DepositModal',
    props: {
        paymentType: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            name: '',
            surname: '',
            phone: '',
            steamId: '',
            country: '',
            city: '',
            address: '',
            postCode: '',
            email: '',
            type: this.paymentType,
            countryOptions: [],
            nameValid: true,
            surnameValid: true,
            phoneValid: true,
            steamIdValid: true,
            countryValid: true,
            cityValid: true,
            addressValid: true,
            postCodeValid: true,
            emailValid: true,
            formValid: true,
            terms: false
        }
    },
    computed: {
        ...mapState('user', ['user']),
        ...mapGetters("app", ["currencySymbol", "currency"]),
        currencyValid() {
            return this.currency !== null;
        },
        requiredFieldsAreFilled() {
            let fieldsAreFilled;
            if(
                this.terms 
            ) {
                fieldsAreFilled = true;
            } else {
                fieldsAreFilled = false;
            }
            return fieldsAreFilled;
        }
    
    },
    mounted() {
        this.getCountries();
        if (this.user && this.user.email) {
            this.email = this.user.email;
        }
    },
    beforeDestroy() {
        this.closeCheckModal();
    },
    methods: {
        ...mapActions('user', ['handlePurchase']),
        ...mapActions("modals", ["closeCheckModal"]),
        submitCheckout() {
            this.validateForm();
            if (this.formValid) {
                let data = {
                    "name": this.name,
                    "surname": this.surname,
                    "phone": this.phone,
                    "steamId": this.steamId,
                    "country": this.country.id,
                    "city": this.city,
                    "address": this.address,
                    "postCode": this.postCode,
                    "paymentType": this.paymentType,
                    "email": this.email
                }
                this.processCheckout(data);
            }
        },
        getCountries() {
            this.$http.get(process.env.VUE_APP_API + 'countries')
                .then((res) => {
                    this.countryOptions = res.data.payload;
                    this.countryOptionsIso = res.data.payload.map(obj => obj.iso);
                })
                .catch((res) => {
                    if (res.response.status === 401) {
                        this.$parent.openSignInModal();
                    }
                })
        },
        goToPage(slug) {
            this.$router.push({ name: 'TextPage', params: { slug } });
        },
        validateForm() {
            this.nameValid = this.name.trim() !== '';
            this.surnameValid = this.surname.trim() !== '';
            this.phoneValid = this.phone.trim() !== '';
            this.steamIdValid = this.steamId.trim() !== '';
            this.countryValid = this.country !== '';
            this.cityValid = this.city.trim() !== '';
            this.addressValid = this.address.trim() !== '';
            this.postCodeValid = this.postCode.trim() !== '';
            this.emailValid = this.user.email ? true : this.email.trim() !== '';
            this.formValid = this.nameValid && this.surnameValid && this.phoneValid && this.steamIdValid && this.countryValid && this.cityValid && this.addressValid && this.postCodeValid && this.emailValid && this.currencyValid;
        },
        async processCheckout(data) {
            data.currency = this.currency;

          await this.handlePurchase(data);
        },
    }
}
</script>