<template>
  <div class="checkout">
    <Transition name="fade">
      <CheckModal v-show="checkModalVisible" :paymentType="paymentType" />
    </Transition>
    <div class="checkout__content">
      <a class="checkout__back-button" @click="$router.back()">
        <img src="../../src/assets/arrow.svg" alt="Back" class="checkout__back-icon" />
        <span>{{ $t('Back') }}</span>
      </a>

      <div class="checkout__container">
        <div class="checkout__left">
          <h2 class="checkout__heading">{{ $t('Checkout') }}</h2>

          <!-- <div class="checkout__alert-block" v-if="$parent.totalSum > user.balance">
            <div class="checkout__alert-left">
              <div class="checkout__alert-content">
                <img src="../../src/assets/alert.svg" alt="Alert" class="checkout__alert-icon" />
                <div class="checkout__alert-text">
                  <h3 class="checkout__alert-title">{{ $t('Not enough funds') }}</h3>
                  <p>You cannot complete your order because you don't have enough funds in your account.</p>
                </div>
              </div>
              <router-link
                :to="{ name: 'Profile', query: { tab: 'account-balance' } }"
                class="checkout__button checkout__button--primary"
                >{{ $t('Top up balance') }}</router-link
              >
            </div>
          </div> -->

          <div class="checkout__cart-block">
            <div class="checkout__cart-header">
              <div class="checkout__cart-line">
                <h2 class="checkout__heading">{{ $t('Shopping cart') }}</h2>
                <div class="checkout__cart-count">{{ cartContents.length || 0 }} {{ $t('items') }}</div>
              </div>
              <div class="checkout__cart-actions" @click="handleClearCartButton">
                <img src="../../src/assets/trash-bin.svg" alt="Clear cart" class="checkout__cart-icon" />
                <span>{{ $t('Clear cart') }}</span>
              </div>
            </div>

            <div class="checkout__order-separator"></div>

            <template v-for="(item, index) in cartContents">
              <div class="checkout__cart-item" :key="item.item.item_id">
                <div class="checkout__cart-item-info">
                  <img :src="imgDomain + item.item.img_url" alt="Item" class="checkout__cart-item-image" />
                  <div>
                    <p class="checkout__cart-item-weapon">{{ item.item.type }}</p>
                    <p class="checkout__cart-item-name">{{ item.item.title }}</p>
                    <p class="checkout__cart-item-exterior">{{ item.item.type }}</p>
                  </div>
                </div>
                <div class="checkout__cart-item-price">
                  <div class="checkout__cart-item-price-text">
                    {{ currencySymbol }} {{ item.item.price.toFixed(2) }}
                  </div>
                  <img
                    src="../../src/assets/trash-bin.svg"
                    alt="Remove"
                    class="checkout__cart-item-icon"
                    @click="$parent.removeFromCart({ item_id: item.item_id })"
                  />
                </div>
              </div>
              <div
                class="checkout__order-separator"
                :key="item.item.item_id"
                v-if="index !== cartContents.length - 1"
              ></div>
            </template>

            <div v-if="cartContents.length === 0" class="checkout__empty-cart">
              <!-- <img src="../../src/assets/empty-cart.svg" alt="Empty cart" class="checkout__empty-cart-icon" /> -->
              <p>{{ $t('Your cart is empty') }}</p>
            </div>
          </div>
        </div>

        <div class="checkout__right">
          <div class="checkout__order-summary">
            <h2 class="checkout__heading">{{ $t('Order Summary') }}</h2>
            <p class="checkout__order-info">
              <span class="bold">{{ cartContents.length || 0 }} {{ $t('items') }}</span> {{ $t('in the cart') }}
            </p>
            <div class="checkout__order-row">
              <span class="checkout__order-label">Steam {{ $t('price') }}:</span>
              <span>{{ currencySymbol }} {{ $parent.totalSum }}</span>
            </div>
            <div class="checkout__order-separator"></div>
            <div class="checkout__order-total">
              <span>{{ $t('Total') }}:</span>
              <span>{{ currencySymbol }} {{ $parent.totalSum }}</span>
            </div>
            <div v-if="$parent.totalSum < $parent.minAmount[currency]" class="checkout__deposit-info">
              <img src="../../src/assets/alert.svg" alt="Alert" class="checkout__deposit-icon" />
              <span>
                {{ $t('Min checkout sum is ') }} {{ $parent.minAmount[currency] || $parent.minAmount }} {{ currency }}
              </span>
            </div>
            <div class="checkout__deposit_topup">
              <!-- <router-link v-if="$parent.totalSum > user.balance"
                :to="{ name: 'Profile', query: { tab: 'account-balance' } }"
                class="checkout__button checkout__button--primary">{{ $t('Top up balance') }}</router-link>
              <button v-else :disabled="$parent.totalSum <= 0" class="checkout__button checkout__button--primary"
                @click="submit">
                {{ $t('ORDER') }}</button> -->
              <div class="profile-page">
                <div class="account-balance__row account-balance__row_2">
                  <template v-for="item in $parent.paymentMethods">
                    <button
                      :key="item.code"
                      v-if="item.currencies.includes(currency)"
                      :class="[
                        'btn',
                        { 'account-balance__button account-balance__button--primary': !item.image },
                        { 'btn-img': item.image },
                        { disabled: $parent.totalSum < $parent.minAmount[currency] }
                      ]"
                      @click="handleChekout(item.code)"
                    >
                      <span v-if="!item.image">{{ item.title }}</span>
                      <img v-else :src="imgDomain2 + item.image" :alt="item.title" class="img" />
                    </button>
                  </template>
                  <button :class="['btn btn-img', {'disabled': $parent.totalSum < $parent.minAmount[currency]}]" @click="handleChekout('balance')">
                    <span>{{ $t('BALANCE') }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex'
  import Vue from 'vue'
  import CheckModal from '../components/modals/CheckModal.vue'

  export default {
    name: 'Checkout',
    props: [],
    components: {
      CheckModal
    },
    data: function () {
      return {
        imgDomain: '',
        imgDomain2: '',
        countryOptions: [],
        countryOptionsId: [],
        name: '',
        surname: '',
        phone: '',
        steamId: '',
        country: '',
        countryId: '',
        city: '',
        address: '',
        postCode: '',
        terms: false,
        phoneFull: '',
        convertedShortName: '',
        countryCode: '',
        countryCodeList: [],
        profilePhone: '',
        countryOptionsIso: [],
        paymentType: ''
      }
    },
    mounted() {
      this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN
      this.imgDomain2 = process.env.VUE_APP_DOMAIN
      this.fetchProfile()
      this.fetchCartContents()
    },
    computed: {
      ...mapState('user', ['user']),
      ...mapGetters('user', ['cartContents']),
      ...mapGetters('app', ['currencySymbol', 'currency']),
      ...mapState('modals', ['checkModalVisible'])
    },
    methods: {
      ...mapActions('modals', ['openCheckModal']),
      handleChekout(type) {
        this.paymentType = type
        if (type == 'balance') {
          let data = { paymentType: type }
          this.handlePurchase(data)
        } else {
          this.openCheckModal()
        }
      },
      ...mapActions('user', ['handlePurchase', 'fetchProfile', 'fetchCartContents', 'clearCart']),
      async submit() {
        try {
          const payload = {
            currency: this.currency
          }
          await this.handlePurchase(payload)
          this.fetchProfile()
          this.fetchCartContents()
          this.$router.push({ name: 'Profile', query: { tab: 'purchase-history' } })
          Vue.$toast.success('Purchased successfully')
        } catch (error) {
          // Handle purchase error
          Vue.$toast.error(error.response.data.message)
        }
      },
      getAllCountryCodes() {
        this.$http
          .get(process.env.VUE_APP_API + 'phone-codes')
          .then((res) => {
            if (res.data.status == 'OK') {
              this.countryCodeList = res.data.payload
              this.countryCode = this.getSymbolsBeforeSpace(this.profilePhone).replace('+', '')
              this.getShortNameByPhoneCode(this.countryCode, this.countryCodeList)
            }
          })
          .catch((res) => {
            if (res.response.status === 401) {
              this.$parent.openSignInModal()
            }
          })
      },
      getShortNameByPhoneCode(phoneCode, codeList) {
        for (let i = 0; i < codeList.length; i++) {
          const countryCode = Object.keys(codeList[i])[0]
          const codeValue = codeList[i][countryCode].code
          if (codeValue == phoneCode) {
            this.convertedShortName = countryCode
          }
        }
      },
      getSymbolsBeforeSpace(inputString) {
        const regex = /^[^\s]+/
        const match = inputString.match(regex)

        if (match) {
          return match[0]
        } else {
          return '' // Return an empty string if no symbols are found
        }
      },
      phoneDataUpdate(e) {
        this.phoneFull = e.formatInternational
      },
      async handleClearCartButton() {
        const result = await this.clearCart()
        if (result === 'CLEAR_CART_ERROR') {
          this.$toast.error('Failed to clear cart. Please try again later.')
        } else {
          this.$toast.success('Cart cleared successfully')
        }
      }
    }
  }
</script>

<style scoped></style>
